import './styles.css';
import LeftColumn from './components/LeftColumn';
import RightColumn from './components/RightColumn';
// import MouseEffect from './components/right-column-elements/MouseEffect';

function App() {
  return (
    <div className="App">
      <LeftColumn />
      <RightColumn />
      {/* <MouseEffect /> */}
    </div>
  );
}

export default App;
